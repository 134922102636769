import React from "react"
import Layout from "../components/layout"
import Shedrach from "../images/shedrach.png"
import Jonathan from "../images/jonathan.png"
import Lisa from "../images/lisa.png"
import DataDriven from "../images/icon-data-driven.svg"
import Generosity from "../images/icon-generosity.svg"
import Hope from "../images/icon-hope.svg"
import Humility from "../images/icon-humility.svg"
import Justice from "../images/icon-justice.svg"
import LearnerCentric from "../images/icon-learner-centric.svg"
import Mutuality from "../images/icon-mutuality.svg"
import Partnership from "../images/icon-partnership.svg"
import Peacemaking from "../images/icon-peacemaking.svg"
// import { Link } from "gatsby"
import { Helmet } from "react-helmet"

export default function WhoWeAre() {
    return (
        <Layout>
            <Helmet>
				<meta charSet="utf-8" />
				<title>JustPax Collective | Who We Are</title>
				<link rel="canonical" href="http://justpaxcollective.com" />
			</Helmet>
            <section class="section--our-founders">
                <section class="wrapper">
                    <h1>Our Founders</h1>
                    <p>The story of beginnings is important. After years of leading strategic change initiatives and working in organizational management, we started fielding requests for help in diversity, equity, and inclusion (DEI) efforts. JustPax Collective came together as a creative response to amplify and expand on current DEI efforts. We exist to challenge the status quo and equip organizations to build, develop, retain, and empower a diverse team in today’s changing world.</p>
                </section>
                <section class="section--founders-bios wrapper flex-container">
                    <div class="bio" style={{marginLeft:`0`}}>
                        <img src={Shedrach} alt="Shedrach" />
                        <h2>Shedrach</h2>
                        <p>Shedrach Rowry earned his degree from Austin Graduate School of Theology and has authored multiple books. He is the CEO of The Levite Laboratory, a music development, performance, and production house that has recorded multiple albums and led worship for conferences all over the world, including: the House of Blues, SXSW music festival, Austin City Limits, Trinity Broadcasting Network, The Word Network, T.D. Jakes’ MegaFest Conference, Urbana Mission Conference, and Novaro Gospel Festival in Italy. He also co-founded The Refuge Place in Pflugerville, TX and serves on staff at the Texas Baptist Children’s Home. Shedrach lives in Austin with the love of his life, Kraven, and their three wonderfully smart and talented children, Isaac, Ariana and Elise.</p>
                    </div>
                    <div class="bio">
                        <img src={Jonathan} alt="Jonathan Eng" />
                        <h2>Jonathan</h2>
                        <p>Jonathan partners with leaders to consult on effective strategies to advance and expand on diversity, equity, and inclusion efforts. As an organizational developer, educator, and justice advocate, Jonathan founded JustPax Collective in response to leaders seeking grounded expertise in DEI strategy, racial justice initiatives, and peacemaking work. He brings together his background in pedagogical design and coaching to empower organizations to make lasting, impactful change. He holds a Master of Divinity from Fuller Theological Seminary and currently serves as the planting and campus pastor of Gateway Church Pflugerville. Jonathan loves writing, enjoying coffee with family and friends, shooting hoops, and moving communities forward in justice and peace.</p>
                    </div>
                    <div class="bio">
                        <img src={Lisa} alt="Lisa Jacbos" />
                        <h2>Lisa</h2>
                        <p>Lisa is an ordained pastor at Gateway Church in Austin, TX. She has a degree in social work and has worked with refugees and migrant communities, providing social services and access to resources. She also has a masters from Dallas Theological Seminary and worked as a clinical therapist, certified in trauma-informed counseling. For the past ten years, she has worked with churches and organizations in developing inclusion, reconciliation, and justice initiatives, leading groups and communities in theology, praxis, and systemic conversations, and engaging community advocacy work both locally and globally through various organizational partnerships and local government. Lisa is a daughter of South Asian immigrants, a little sister, and a dog mom. In her spare time, she loves trying new foods, seeing the world, walking trails, and almost any kind of board game.</p>
                    </div>
                </section>
            </section>
            <section class="section--our-collective">
                <section className="wrapper">
                    <h1>Our Collective</h1>
                    <p>We’re stronger (and smarter) together. We bring our individual strengths and experiences to drive organizational learning around DEI and community impact. Whether you are a start-up, a seasoned company, a non-profit, or a faith community, we leverage our cross-industry experience to evolve organizational cultures for the flourishing of all.</p>
                </section>
            </section>
            
            <section class="section--our-pledge">
                <section className="wrapper">
                    <h1>We Give Back</h1>
                    <p>From the start, JustPax was built with a shared commitment to drive high-impact change through our work and community partnerships. Our 3 founders wanted to move beyond words and implement an ongoing practice to give back. Through our 10% Initiative, we donate 10% of our net profits to organizations that share our goals for mobilizing justice and peacemaking for the flourishing of our world.</p>
                    <p><strong>Current partnerships include:</strong><br />Seedling, Free Books Project, EJI, current grassroots opportunity</p>
                </section>
            </section>
            <section class="section--core-practices">
                <section className="wrapper">
                    <h1>JustPax's Core Practices</h1>
                    <p>While we believe in the importance of core values, we find that core practices are a better reflection of our commitments. As the saying goes, “Don’t talk the talk if you can’t walk the walk.”</p>
                    <div class="flex-container">
                        <div class="card--practice">
                            <img src={LearnerCentric} alt="Learner Centric" />
                            <h3>Learner-centric</h3>
                            <p>Many trainings rely on outdated models that rarely engage active learning and participation, both of which are required for lasting and transformative change. We deliver training programs that are built on successful learning principles and human-centered approaches.</p>
                        </div>
                        <div class="card--practice">
                            <img src={Justice} alt="Justice" />
                            <h3>Justice</h3>
                            <p>Our work is undergirded and guided by a vision of restorative justice in which all are involved in the work of equity and change.</p>
                        </div>
                        <div class="card--practice">
                            <img src={Humility} alt="Humility" />
                            <h3>Humility</h3>
                            <p>None of us have fully arrived. We are lifelong learners who regularly challenge ourselves, second-guess our conclusions, and ask, “What if..?”. We stay dialed-in on our ever-changing environments. We don’t shy away from tough conversations and welcome feedback as we disrupt the status quo and co-create a new tomorrow.</p>
                        </div>
                    </div>
                    <div class="flex-container">
                        <div class="card--practice">
                            <img src={Partnership} alt="Partnership" />
                            <h3>Partnership</h3>
                            <p>Because DEI work often involves hard truth, we focus on building a foundation of trust, openness, and empathy. We lead with grace, and we also won’t leave you guessing about difficult realities.</p>
                        </div>
                        <div class="card--practice">
                            <img src={Hope} alt="Hope" />
                            <h3>Hope</h3>
                            <p>We practice hope daily. We believe in a world that will one day not need this work, and we’re committed to doing our part until that day comes.</p>
                        </div>
                        <div class="card--practice">
                            <img src={Generosity} alt="Generosity" />
                            <h3>Generosity</h3>
                            <p>Through our 10% Initiative, we donate 10% of our net profits to organizations that share our goals for mobilizing justice and peacemaking for the flourishing of our world.</p>
                        </div>
                    </div>
                    <div class="flex-container">
                        <div class="card--practice">
                            <img src={Peacemaking} alt="Peacemaking" />
                            <h3>Peacemaking</h3>
                            <p>We reject false unity and pursue a true peace in which all can flourish. To that end, we aren’t afraid to (respectfully) rock the boat a bit as we work together to heal divisions.</p>
                        </div>
                        <div class="card--practice">
                            <img src={Mutuality} alt="Mutuality" />
                            <h3>Mutuality</h3>
                            <p>We establish partnerships that recognize and uplift our shared human dignity. We work to ensure that everyone gets to the table and can flourish.</p>
                        </div>
                        <div class="card--practice">
                            <img src={DataDriven} alt="Data Driven" />
                            <h3>Data-driven</h3>
                            <p>We utilize tools and metrics to establish context and measure DEI growth so that your team can keep momentum moving forward.</p>
                        </div>
                    </div>
                </section>
            </section>
        </Layout>
    )
}